<template>
  <div class="dialog">
    <div class="dialog-header" v-if="showDialogHeader">
      <div class="row">
        <div class="col">
          <p class="dialog-title text-uppercase mb-0">Add School</p>
        </div>
      </div>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <div class="default-logos">
            <span>Choose a default logo or upload an image:</span>
            <div class="logos">
              <span  v-for="(img, index) in defaultLogos"
                     :key="index"
                     :class="{active: img.asset_url === currentLogo}"
                     class="position-relative ml-1 mr-1"
                     @click="useDefaultLogo(img.asset_url)">
                <b-img :src="img.asset_url"
                      v-bind="mainProps"
                      rounded="0"
                ></b-img>
              </span>
            </div>
          </div>
          <croppa v-if="showCroppa"
                  accept=".jpeg,.png,.jpg"
                  auto-sizing
                  canvas-color="#fff"
                  placeholder-color="#068bcd"
                  remove-button-color="red"
                  v-model="file"
                  :key="timestamp"
                  :width="200"
                  :height="200"
                  :placeholder="placeholder"
                  :placeholder-font-size="12"
                  :prevent-white-space="false"
                  :remove-button-size="25"
                  :show-loading="false"
                  :loading-size="25"
                  @file-type-mismatch="onFileTypeMismatch"
                  @new-image="imageUpdate('new')"
                  @image-remove="imageUpdate('removed')">
                  <img class="current-logo"
                       v-if="currentLogo"
                       slot="initial"
                       :src="currentLogo" />
          </croppa>
          <div class="buttons">
            <i class="fas fa-search-plus icon"
               @click="file.zoomIn()"
            />
            <i class="fas fa-search-minus icon"
               @click="file.zoomOut()"
            />
            <i class="fas fa-save icon"
               :class="{'hide-button': hideButton}"
               @click="uploadFile"
            />
          </div>
        </div>
        <div class="col-12 col-md-8">
          <b-form class="mt-3">
            <b-form-group>
              <b-form-input id="schoolName"
                            type="text"
                            aria-describedby="schoolName"
                            autocomplete="off"
                            placeholder="School Name*"
                            :state="!$v.form.schoolName.$error"
                            @input="$v.form.schoolName.$touch()"
                            v-model="form.schoolName"
                            @focusout="sanitizeWhitespace('schoolName',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="schoolName">
                <span v-if="!$v.form.schoolName.required">
                  School Name is required
                </span>
                <span v-if="!$v.form.schoolName.safeSchoolName">
                  School Name not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input id="address1"
                            type="text"
                            aria-describedby="address1"
                            autocomplete="off"
                            placeholder="Bldg name, Street name/no."
                            :state="!$v.form.address1.$error"
                            @input="$v.form.address1.$touch()"
                            v-model="form.address1"
                            @focusout="sanitizeWhitespace('address1',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="address1">
                <span v-if="!$v.form.address1.safeAddress1">
                  Address not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input id="address2"
                            type="text"
                            aria-describedby="address2"
                            autocomplete="off"
                            placeholder="Block no. , Area Name"
                            :state="!$v.form.address2.$error"
                            @input="$v.form.address2.$touch()"
                            v-model="form.address2"
                            @focusout="sanitizeWhitespace('address2',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="address2">
                <span v-if="!$v.form.address2.safeAddress2">
                  Address not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <v-select class="country-select selection"
                        :class="$v.form.country.$error ? 'invalid' : 'valid'"
                        aria-describedby="country"
                        v-model="form.country"
                        :options="countries"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="Country*"
                        @input="onSelectCountry"
              ></v-select>
              <div class="error" v-if="$v.form.country.$error">
                Country is required
              </div>
            </b-form-group>
            <b-form-group v-if="states.length">
              <v-select class="state-select selection"
                        :class="$v.form.state.$error ? 'invalid' : 'valid'"
                        v-model="form.state"
                        :options="states"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="State*"
                        @input="onSelectState"
              ></v-select>
              <div class="error" v-if="$v.form.state.$error">
                State is required
              </div>
            </b-form-group>
            <b-form-group v-if="counties.length">
              <v-select class="county-select selection"
                        v-model="form.county"
                        :options="counties"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="County"
              ></v-select>
            </b-form-group>
            <b-form-group v-if="cities.length">
              <v-select class="city-select selection"
                        :class="$v.form.city.$error ? 'invalid' : 'valid'"
                        v-model="form.city"
                        :options="cities"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="City*"
                        @input="onSelectCity"
              ></v-select>
              <div class="error" v-if="$v.form.city.$error">
                City is required
              </div>
            </b-form-group>
            <b-form-group v-if="schoolDistricts.length">
              <v-select class="form-control school-district-select"
                        v-model="form.schoolDistrict"
                        :options="schoolDistricts"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="School District"
              ></v-select>
            </b-form-group>
            <b-form-group>
              <b-form-input id="postalCode"
                            type="text"
                            aria-describedby="postal_code"
                            autocomplete="off"
                            placeholder="Postal Code"
                            :state="!$v.form.postalCode.$error"
                            @input="$v.form.postalCode.$touch()"
                            v-model="form.postalCode"
                            @focusout="sanitizeWhitespace('postalCode',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="postalCode">
                <span v-if="!$v.form.postalCode.numeric">
                  Postal Code should be Numeric
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
      </div>

      <!-- Footer -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-12 my-auto">
          <div class="left">
            <small class="required text-left">
              <i>*Required fields</i>
            </small>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-12">
          <div class="right">
            <b-button class="btn btn-success mx-2 btn-h3-small"
                      @click="submit"
                      :disabled="$v.$invalid">
              Submit
            </b-button>
            <b-button class="btn btn-danger mx-2 btn-h3-small ml-2"
                      @click="closeModal">
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  import 'vue-croppa/dist/vue-croppa.css';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import ProfanityMixin from '@/mixins/ProfanityMixin';
  import { required, numeric } from 'vuelidate/lib/validators';
  import { mapActions } from 'vuex';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  export default {
    name   : 'AddSchoolModal',
    mixins : [
      BreakpointMixin,
      ProfanityMixin,
    ],
    props : {
      cancelable : {
        type    : Boolean,
        default : true,
      },
      showDialogHeader : {
        type    : Boolean,
        default : true,
      },
    },
    components : {
      Loading,
      RainbowLoader,
    },
    data() {
      return {
        isLoading       : false,
        file            : null,
        placeholder     : 'Choose an image*',
        currentLogo     : null,
        hideButton      : true,
        countries       : [],
        states          : [],
        counties        : [],
        schoolDistricts : [],
        cities          : [],
        form            : {
          schoolId       : null,
          schoolName     : null,
          address1       : null,
          address2       : null,
          country        : null,
          state          : null,
          county         : null,
          city           : null,
          schoolDistrict : null,
          postalCode     : null,
        },
        defaultLogos : [],
        mainProps    : {
          width  : 70,
          height : 70,
          class  : 'm1',
        },
        safeSchoolName : true,
        safeAddress1   : true,
        safeAddress2   : true,
        showCroppa     : true,
        timestamp      : Date.now(),
        usedCroppa     : false,
      };
    },
    watch : {
      currentLogo() {
        this.$v.currentLogo.$touch();
      },
    },
    methods : {
      ...mapActions({
        'getUpdatedUser' : 'user/getUpdatedUser',
      }),

      /**
       * Get Default Logos
       */
      getDefaultLogos() {
        this.$http.get('api/school/logos')
          .then((res) => {
            this.defaultLogos = res.data;
          }).catch(() => {});
      },

      /**
       * Get Countries
       */
      getCountries() {
        this.$http.get('api/country/list')
          .then((res) => {
            this.countries = res.data;
          }).catch(() => {
            this.countries = [];
          });
      },

      /**
       * Update states on country update
       *
       * @param id
       */
      onSelectCountry(id) {
        this.$v.form.country.$touch();
        this.$http.get('api/state/by_country', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.states = res.data;
          })
          .catch(() => {
            this.states = [];
          });

        this.counties = [];
        this.cities = [];
        this.schoolDistricts = [];

        this.form.state = null;
        this.form.county = null;
        this.form.city = null;
        this.form.postalCode = "";
        this.form.schoolDistrict = null;
      },

      /**
       * Update counties and cities on state update
       *
       * @param id
       */
      onSelectState(id) {
        this.$v.form.state.$touch();
        this.$http.get('api/city/by_state', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.cities = res.data;
          })
          .catch(() => {
            this.cities = [];
          });

        this.$http.get('api/county/by_state', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.counties = res.data;
          })
          .catch(() => {
            this.counties = [];
          });

        this.schoolDistricts = [];
        this.form.county = null;
        this.form.city = null;
        this.form.postalCode = "";
        this.form.schoolDistrict = null;
      },

      /**
       * Update school districts on city update
       *
       * @param id
       */
      onSelectCity(id) {
        this.$v.form.city.$touch();
        this.$http.get('api/school_district/by_city', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.schoolDistricts = res.data;
          })
          .catch(() => {
            this.schoolDistricts = [];
          });

        this.form.postalCode = "";
        this.form.schoolDistrict = null;

      },

      /**
       * Update School Logo using Default Image
       * @param assetUrl
       */
      useDefaultLogo(assetUrl) {
        this.showCroppa = false;
        this.timestamp = Date.now();
        this.currentLogo = assetUrl;
        this.showCroppa = true;
      },

      /**
       * Submit Form
       */
      submit() {
        this.isLoading = true;
        this.$http.post('api/school', {
          // eslint-disable-next-line camelcase
          school_name        : this.form.schoolName,
          address1           : this.form.address1 || null,
          address2           : this.form.address2 || null,
          // eslint-disable-next-line camelcase
          country_id         : this.form.country || null,
          // eslint-disable-next-line camelcase
          state_id           : this.form.state || null,
          // eslint-disable-next-line camelcase
          county_id          : this.form.county || null,
          // eslint-disable-next-line camelcase
          school_district_id : this.form.schoolDistrict || null,
          // eslint-disable-next-line camelcase
          city_id            : this.form.city || null,
          // eslint-disable-next-line camelcase
          postal_code        : this.form.postalCode || null,
          // eslint-disable-next-line camelcase
          logo_url           : this.currentLogo || null,
        }).then(response => {
          localStorage.setItem('selectedSchoolId', response.data.insertId);

          if (this.currentLogo === null)
            this.uploadFile();
          else {
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'School Successfully Added.',
            });
            this.isLoading = false;
            this.closeModal();
          }
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Upload File
       */
      async uploadFile() {
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.jpg');
          formData.append('imageType', 'school');
          formData.append('id', localStorage.selectedSchoolId);

          try {
            await this.$http.post('/api/upload/image', formData, {});
            if (this.cancelable) {
              const that = this;
              setTimeout(async function () {
                that.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success',
                  text  : 'School Successfully Added.',
                });
                that.isLoading = false;
                that.closeModal();
              }, 2000);
            } else {
              // For No School Modal
              this.updateProfile();
            }
          } catch (err) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong!',
            });
          }
        }, 'image/jpeg');
      },

      /**
       * Update User Profile
       */
      updateProfile() {
        this.$http.put('api/user', {
          'userId'       : this.$store.getters['user/user'].user_id,
          'updateFields' : {
            'school_id' : localStorage.selectedSchoolId,
          },
        }).then(() => {
          this.isLoading = false;
          this.getUpdatedUser();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'School has been added and your profile has been updated!',
          });
          this.$emit('successful');
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Disable button on image croppa update
       *
       * @param status
       */
      imageUpdate(status) {
        if(status === 'new'){
          this.hideButton = false;
          this.usedCroppa = true;
        } else {
          this.hideButton = true;
          this.usedCroppa = false;
          this.file.refresh();
        }
        this.currentLogo = null;
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch() {
        this.placeholder = 'Invalid file type';
      },

      /**
       * Close Modal
       */
      closeModal() {
        if (this.cancelable)
          this.$emit('close');
        else
          this.$emit('cancel-add-school');
      },

      /**
       * Reset Add School Form
       */
      resetAddSchool() {
        this.form.schoolName = null;
        this.form.address1 = null;
        this.form.address2 = null;
        this.form.country = null;
        this.form.state = null;
        this.form.county = null;
        this.form.schoolDistrict = null;
        this.form.city = null;
        this.form.postalCode = null;
        this.form.currentLogo = null;

        // reset validation
        this.$v.$reset();
      },
    },
    created() {
      this.getDefaultLogos();
    },
    mounted() {
      this.getCountries();
    },
    validations() {
      return {
        file        : (this.currentLogo !== null) ? {} : { required },
        currentLogo : (this.usedCroppa) ? {} : { required },
        form        : {
          schoolName : {
            required,
            minLength : 2,
            safeSchoolName() {
              return this.safeSchoolName;
            },
          },
          address1 : {
            safeAddress1() {
              return this.safeAddress1;
            },
          },
          address2 : {
            safeAddress2() {
              return this.safeAddress2;
            },
          },
          country    : { required },
          state      : (this.states.length > 0) ? { required } : {},
          city       : (this.cities.length > 0) ? { required } : {},
          postalCode : { numeric },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/add-school";
</style>